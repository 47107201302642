import Image from 'next/image'
import styled from 'styled-components'
import { Row, theme } from '@/client/components'
const { space } = theme

export const RowBreadcrumb = styled(Row)`
  padding-top: ${space.lg};
`

export const NotFoundImg = styled(Image)`
  display: block;
  width: 100%;
  max-width: 34rem;
  height: auto;
  margin: 0 auto ${space.xl};
`
