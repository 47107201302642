import { FC, memo } from 'react'
import { Row, Col, Text, Breadcrumb, Content } from '@/client/components'
import Page from '@/client/submodules/Page'
import * as S from './styles'
import { PageNotFoundProps } from './types'

export const PageNotFound: FC<PageNotFoundProps> = memo(({ seo, header, footer, content }) => {
  const { breadcrumb, title, description } = content

  return (
    <Page seo={seo} header={header} footer={footer}>
      {breadcrumb && (
        <S.RowBreadcrumb>
          <Col>
            <Breadcrumb pages={breadcrumb} />
          </Col>
        </S.RowBreadcrumb>
      )}

      <Row>
        <Col>
          {title && (
            <Text as='h1' size='xxlg' family='heading' weight='bold' margin='1rem 0 1.5rem'>
              {title}
            </Text>
          )}

          <S.NotFoundImg src='/img/404.png' alt='' width='1200' height='894' />

          {description && <Content content={description} />}
        </Col>
      </Row>
    </Page>
  )
})

PageNotFound.displayName = 'PageNotFound'
