import type { NextPage } from 'next'
import { PageNotFound } from '@/client/modules/PageNotFound'
import { PageNotFoundProps } from '@/client/modules/PageNotFound/types'
import { getCMSContent } from '@/client/utils'

const Page404: NextPage<PageNotFoundProps> = (data) => <PageNotFound {...data} />

export async function getStaticProps({ defaultLocale, locale }) {
  const data = await getCMSContent(defaultLocale, locale, 'PAGE_NOT_FOUND')

  return {
    revalidate: 10,
    props: { ...data }
  }
}

export default Page404
